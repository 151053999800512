.responseModal {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(13, 13, 13, 0.7);
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100vh;
  width: 100%;
  z-index: 1015;
}

.responseModal__container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #fff;
  flex-direction: column;
  min-height: 20vh;
  min-width: 25vw;
  max-width: 30vw;
  color: #1e3a8a;
  padding: 2vw 2vw;
  border-radius: 2.5vh;
  border: 1px solid #5bc0eb;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 1.1vw;
}

.responseModal__message {
  margin: 0 auto;
  min-height: 10vh;
  width: 85%;
  font-size: 1.5vw;
  text-align: center;
}

.button {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background-color: #5bc0eb;
  cursor: pointer;
}

.pop__up {
  animation: pop-up 0.3s forwards;
  -webkit-animation: pop-up 0.3s forwards;
}

@-webkit-keyframes pop-up {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.8);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}

@keyframes pop-up {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.pop__down {
  animation: pop-down 0.3s forwards;
  -webkit-animation: pop-down 0.3s forwards;
}

@-webkit-keyframes pop-down {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }
}

@keyframes pop-down {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}
